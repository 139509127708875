import Footer from './common/Footer';
import ComingSoon from './public/ComingSoon';
import './styles/Styles.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <div className="min-h-[100dvh] justify-end flex flex-col">
            <ComingSoon />
            <Footer />
          </div>
        </Route>
        <Route path="/dohodnina" exact>
          <iframe
            style={{
              position: 'fixed',
              top: '0px',
              bottom: '0px',
              right: '0px',
              width: '100%',
              border: 'none',
              margin: '0',
              padding: '0',
              overflow: 'hidden',
              zIndex: '999999',
              height: '100%',
            }}
            frameborder="0"
            src="https://izpolni-si.vercel.app/"
          />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
