import { Container, Grid } from '@material-ui/core';
import { Facebook, Instagram } from '@mui/icons-material';

const Footer = () => {
  return (
    <Grid className="footer border bg-[#383838] pb-8">
      <Container>
        <Grid
          container
          style={{ color: 'white', fontSize: 14, lineHeight: 1.3 }}
        >
          <Grid item xs={12} sm={4} className="footer-grid-item">
            <Grid item xs={12} className="footer-title">
              <h2>Osnovni podatki</h2>
            </Grid>
            <Grid item xs={12}>
              PGD Begunje na Gorenjskem
            </Grid>
            <Grid item xs={12}>
              Begunje 15 C
            </Grid>
            <Grid item xs={12}>
              4275 Begunje na Gorenjskem
            </Grid>
            <Grid item xs={12}>
              M.št.: 5133548000
            </Grid>
            <Grid item xs={12}>
              D.št.: 88945901 (nismo zavezanec za DDV)
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} className="footer-grid-item">
            <Grid item className="footer-title pb-4">
              Kontakt
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }}>
              Društvo
            </Grid>
            <Grid item className="pb-2">
              info@pgd-begunje.si
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }}>
              Predsednik
            </Grid>
            <Grid item>Denis Ambrožič</Grid>
            <Grid item>031 404 497 / denis.ambrozic97@gmail.com</Grid>
            <Grid item style={{ paddingTop: 2, fontWeight: 'bold' }}>
              Poveljnik
            </Grid>
            <Grid item>Žan Legat</Grid>
            <Grid item>041 626 442 / zanlegatrotar259@gmail.com</Grid>
            <Grid item style={{ paddingTop: 12, fontWeight: 'bold' }}>
              Vrtne mize, klopi in šotor
            </Grid>
            <Grid item>Tim Ambrožič</Grid>
            <Grid item>070 417 802</Grid>
          </Grid>
          <Grid item xs={12} sm={4} className="footer-grid-item">
            <Grid item className="footer-title">
              Najdete nas tudi na
            </Grid>
            <Grid item>
              <a
                href="https://www.facebook.com/pgdbegunjenagorenjskem"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: 8 }}
              >
                <Facebook style={{ color: '#fff', fontSize: '32px' }} />
              </a>
              <a
                href="https://www.instagram.com/begunjepgd/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram style={{ color: '#fff', fontSize: '32px' }} />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
